import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const Hoecke = () => {
  return(
    <Container className='bjoern nazi'>
      <Row className='justify-content-center mt-5'>
          <Col lg='7' md='8' sm='7' xs='10' xl='6' className='block bernd'>
            <h1 className='l1'>Björn</h1>
            <h1 className='l1'>Höcke</h1>
            <h1 className='l2'>ist ein</h1>
            <h1 className='l3'>Nazi</h1>
          </Col>
      </Row>
    </Container>
  )
}


export default Hoecke;
