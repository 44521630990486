import { Container } from 'react-bootstrap';
import Navigation from './Navigation';

const Impressum = () => {
  return (
    <>
    <Navigation />
    <main className='flex-shrink-0'>
      <Container className='mt-5'>
        <h2>Impressum</h2>
        <p>Angaben gemäß § 5 TMG</p>
        <p>
          Vereinigung der Verfolgten des Naziregimes – Bund der Antifaschistinnen und Antifaschisten (VVN-BdA) e. V.<br />
          Bundesvereinigung<br />
          Magdalenenstr. 19<br />
          10365 Berlin
        </p>
        <p>
          Amtsgericht Charlottenburg<br />
          VR 23190 B<br />
          Steuernummer: 27/680/67093
        </p>
        <p>
          Vertreten durch: Thomas Willms
        </p>
        <p>
          Kontakt:<br />
          Telefon 030 / 55579083-2<br />
          Telefax 030 / 55579083-9<br />
          E-Mail: bundesbuero[at]vvn-bda.de
        </p>
        <p>
          Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:<br />
          Thomas Willms<br />
          Geschäftsstelle VVN-BdA<br />
          Magdalenenstraße 19<br />
          10365 Berlin
        </p>
      </Container>
    </main>
    </>
  )
}

export default Impressum;
