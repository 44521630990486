import { useRouteError } from "react-router-dom"


const ErrorPage = () => {

  const error = useRouteError();

  console.error(error);

  return(
    <div id='error-page' className='container'>
       <h1>Oops!</h1>
        <p>An unexpected error has occured.</p>
        <p>
          <i>
            {error.statusText || error.message }
          </i>
        </p>
        {error.data?.message && <p>{error.data.message}</p>}
    </div>
  )
}

export default ErrorPage
