import Container from 'react-bootstrap/Container'

const Navigation = () => {
  return (
    <div className='top-nav py-3'>
      <Container className=''>
        <nav className='nav'>
          <a className='nav-link' href="/">Start</a>
          <a className='nav-link' href="/impressum">Impressum</a>
        </nav>
      </Container>
    </div>
  )
}

export default Navigation;
